<template>
  <main>
    <router-view />
  </main>
</template>

<style lang="scss" scoped>
.container-main {
  padding: 0;
}
</style>
