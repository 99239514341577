import { AlertSeverity } from '@/types/alertTypes';
import sound from '../assets/file.mp3'

export function showAlert(message: string, severity: AlertSeverity = 'warning', bot: string = '') {
  playSound(sound)
  const alertStore = useAlertsStore();

  alertStore.addAlert({
    message,
    title: `${bot ? 'Bot: ' + bot : 'Notification'}`,
    severity,
    timeout: 5000,
  });
}


export function useAlertForBot(botName: string) {
  
  return {
    showAlert: (message: string, severity: AlertSeverity = 'warning') => {
      showAlert(message, severity, botName);
    },
  };
}

export function playSound(soundFile) {
  // 创建一个新的Audio对象
  const audio = new Audio(soundFile);

  // 尝试播放音频
  audio.play()
    .catch((error) => {
      // 如果播放失败，打印错误信息
      console.error('播放声音失败:', error);
    });
}
